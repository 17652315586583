import api from "../middleware/api";

interface License {
  serialNo: string;
  customer?: string | null;
  controllerName?: string | null;
  simcardSerialNo?: string | null;
  imsi?: string | null;
  ipaddress?: string | null;
  msisdn?: string | null;
  modemFirmware?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  boardType?: string | null;
  apn?: string | null;
  technology?: string | null;
  remark?: string | null;
}

export const LDGetAll = async (
  pageSize = 15,
  pageNumber = 1,
  serialNo?: string
) => {
  try {
    const response = await api.get("/api/LD/getAll", {
      params: {
        pageSize,
        pageNumber,
        serialNo,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const LDGetById = async (id: string | undefined) => {
  try {
    const response = await api.get(`/api/LD/getBySerialNo`, {
      params: { serialNo: id },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const DeleteBySerialNoLD = async (serialNo: string) => {
  try {
    const response = await api.delete("/api/LD/deleteSerialNo", {
      params: { serialNo },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const UpdateBySerialNoLD = async (license: License) => {
  try {
    const formatField = (field: string | null | undefined): string => {
      if (field === null || field === undefined || field === "") {
        return "'N/A";
      }

      return field.startsWith("'") ? field : `'${field}`;
    };

    const response = await api.put(`/api/LD/updateSerialNo`, null, {
      params: {
        serialNo: license.serialNo,
        customer: formatField(license.customer),
        controllerName: formatField(license.controllerName),
        simcardSerialNo: formatField(license.simcardSerialNo),
        imsi: formatField(license.imsi),
        ipaddress: formatField(license.ipaddress),
        msisdn: formatField(license.msisdn),
        modemFirmware: formatField(license.modemFirmware),
        latitude: formatField(license.latitude),
        longitude: formatField(license.longitude),
        boardType: formatField(license.boardType),
        apn: formatField(license.apn),
        technology: formatField(license.technology),
        remark: formatField(license.remark),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
