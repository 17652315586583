import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { LDGetById, UpdateBySerialNoLD } from "../api/LicenseDataAPI";

import styles from "../assets/styles/editLicenseScreen.module.css";
import Loader from "../components/Loader";

interface License {
  customer: string | null;
  controllerName: string | null;
  serialNo: string;
  simcardSerialNo: string | null;
  imsi: string | null;
  ipaddress: string | null;
  msisdn: string | null;
  modemFirmware: string | null;
  latitude: string | null;
  longitude: string | null;
  boardType: string | null;
  apn: string | null;
  technology: string | null;
  remark: string | null;
}

const EditLicenseScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [license, setLicense] = useState<License | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const fetchLicense = async () => {
      try {
        setLoading(true);
        const response = await LDGetById(id);
        setLicense(response.data);
      } catch (error) {
        console.error("Error fetching license:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchLicense();
    }

    if (location.state) {
      setCurrentPage(location.state.currentPage);
      setItemsPerPage(location.state.itemsPerPage);
      setSearchTerm(location.state.searchTerm);
    }
  }, [id, location.state]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (license) {
      const { name, value } = e.target;
      setLicense({
        ...license,
        [name]: value === "" ? null : value,
      });
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    if (license) {
      setLok("");
      let ipAddress = license.ipaddress?.trim().startsWith("'")
        ? license.ipaddress?.trim().slice(1)
        : license.ipaddress?.trim();
      ipAddress = ipAddress ?? "d";
      const ipParts = ipAddress.split(".");
      if (ipParts.length !== 4) {
        setProblem(true);
        setLok(
          (prevState) =>
            (prevState += "IP Address must be in the format x.y.e.z <br />")
        );
        return;
      }

      const isValidIp = ipParts.every((part) => {
        const num = Number(part);

        return !isNaN(num) && num >= 0 && num <= 254;
      });

      if (!isValidIp) {
        setProblem(true);
        setLok(
          (prevState) =>
            (prevState +=
              "Each part of the IP Address must be between 1 and 254. <br />")
        );
        return;
      }

      const firstPart = Number(ipParts[0]);
      const lastPart = Number(ipParts[3]);

      if (firstPart === 0) {
        setProblem(true);
        setLok(
          (prevState) =>
            (prevState +=
              "The first part of the IP Address cannot be 0. <br />")
        );
        return;
      }

      if (lastPart === 0) {
        setProblem(true);
        setLok(
          (prevState) =>
            (prevState += "The last part of the IP Address cannot be 0. <br />")
        );
        return;
      }

      if (
        license.msisdn?.startsWith("'389") ||
        license.msisdn?.startsWith("389")
      ) {
        try {
          await UpdateBySerialNoLD(license);
          navigate("/iot-license-manager", {
            state: {
              id: id,
              currentPage: currentPage,
              searchTerm: searchTerm,
              itemsPerPage: itemsPerPage,
            },
          });
        } catch (error) {
          console.error("Error updating license:", error);
        }
      } else {
        setProblem(true);
        setLok((prevState) => (prevState = "MSISDN must start with 389"));
        return;
      }
    }
  };

  const [problem, setProblem] = useState<boolean>(false);
  const [lok, setLok] = useState<string>("");

  return (
    <div className={styles.editLicenseScreen}>
      <h1>Edit License</h1>
      {license && !loading ? (
        <form onSubmit={handleSave}>
          <div>
            <label>Serial Number:</label>
            <input
              type="text"
              name="serialNo"
              value={license.serialNo}
              onChange={handleChange}
              required
              readOnly
            />
          </div>
          <div>
            <label>Customer:</label>
            <input
              type="text"
              name="customer"
              value={
                license.customer?.startsWith("'")
                  ? license.customer.slice(1)
                  : license.customer || ""
              }
              onChange={handleChange}
              readOnly
            />
          </div>
          <div>
            <label>Controller Name:</label>
            <input
              type="text"
              name="controllerName"
              value={
                license.controllerName?.startsWith("'")
                  ? license.controllerName.slice(1)
                  : license.controllerName || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>SIM Card Serial Number:</label>
            <input
              type="text"
              name="simcardSerialNo"
              value={
                license.simcardSerialNo?.startsWith("'")
                  ? license.simcardSerialNo.slice(1)
                  : license.simcardSerialNo || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>IMSI:</label>
            <input
              type="text"
              name="imsi"
              value={
                license.imsi?.startsWith("'")
                  ? license.imsi.slice(1)
                  : license.imsi || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>IP Address:</label>
            <input
              type="text"
              name="ipaddress"
              value={
                license.ipaddress?.startsWith("'")
                  ? license.ipaddress.slice(1)
                  : license.ipaddress || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>APN:</label>
            <input
              type="text"
              name="apn"
              value={
                license.apn?.startsWith("'")
                  ? license.apn.slice(1)
                  : license.apn || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>MSISDN:</label>
            <input
              type="text"
              name="msisdn"
              value={
                license.msisdn?.startsWith("'")
                  ? license.msisdn.slice(1)
                  : license.msisdn || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Modem Firmware:</label>
            <input
              type="text"
              name="modemFirmware"
              value={
                license.modemFirmware?.startsWith("'")
                  ? license.modemFirmware.slice(1)
                  : license.modemFirmware || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Technology:</label>
            <select
              name="technology"
              className={styles.specialButton}
              value={
                license.technology?.startsWith("'")
                  ? license.technology.slice(1)
                  : license.technology || ""
              }
              onChange={handleChange}
            >
              <option value="">Select Technology</option>
              <option value="2G">2G</option>
              <option value="4G">4G</option>
              <option value="5G">5G</option>
              <option value="NBIoT">NBIoT</option>
              <option value="LTE-M">LTE-M</option>
            </select>
          </div>
          <div>
            <label>Latitude:</label>
            <input
              type="text"
              name="latitude"
              value={
                license.latitude?.startsWith("'")
                  ? license.latitude.slice(1)
                  : license.latitude || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Longitude:</label>
            <input
              type="text"
              name="longitude"
              value={
                license.longitude?.startsWith("'")
                  ? license.longitude.slice(1)
                  : license.longitude || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Board Type:</label>
            <input
              type="text"
              name="boardType"
              value={
                license.boardType?.startsWith("'")
                  ? license.boardType.slice(1)
                  : license.boardType || ""
              }
              onChange={handleChange}
            />
          </div>
          <div>
            <label>Remark:</label>
            <input
              type="text"
              name="remark"
              value={
                license.remark?.startsWith("'")
                  ? license.remark.slice(1)
                  : license.remark || ""
              }
              onChange={handleChange}
            />
          </div>
          <button type="submit">Save</button>
          <button
            type="button"
            onClick={() =>
              navigate("/iot-license-manager", {
                state: {
                  currentPage: currentPage,
                  searchTerm: searchTerm,
                  itemsPerPage: itemsPerPage,
                },
              })
            }
          >
            Cancel
          </button>
          {problem && (
            <div
              className={styles.lok}
              dangerouslySetInnerHTML={{ __html: lok }}
            />
          )}
        </form>
      ) : (
        <Loader className={["centerOfScreen"]} />
      )}
    </div>
  );
};

export default EditLicenseScreen;
